/* eslint-disable react/no-array-index-key */
import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import {
  HeadingWIcon,
  CardArticle,
  CardStat,
  CardQuote,
  CardExcerpt,
} from "@molecules";
import { Text, Container, Button } from "@atoms";
import chunkify from "@utils/chunkify";
import brand from "@utils/brand";
import resolveImage from "@dataResolvers/resolveImage";

const ImpactGrid = ({
  heading: _heading,
  icon,
  copy: _copy,
  impact: _impact,
  inverse,
  link,
}) => {
  const { lgca, romero } = brand;
  const data = useStaticQuery(graphql`
    query HomeImpactQuery {
      craft {
        entry(section: "home", site: "lakotaAc") {
          ... on Craft_home_home_Entry {
            impactGrid {
              ... on Craft_impactGrid_card_BlockType {
                enabled
                copy
                heading
                enabled
                typeHandle
                image {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
              }
              ... on Craft_impactGrid_statCard_BlockType {
                enabled
                stat
                heading
                description
                enabled
                typeHandle
              }
              ... on Craft_impactGrid_quote_BlockType {
                enabled
                id
                quote
                citation
                enabled
                typeHandle
                image {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
              }
              ... on Craft_impactGrid_excerpt_BlockType {
                enabled
                id
                date
                copy
                logo {
                  width
                  height
                  hasFocalPoint
                  slug
                  status
                  title
                  focalPoint
                  url
                }
                enabled
                heading
                subheading
                typeHandle
              }
            }
            heading2
          }
        }
      }
    }
  `);
  const { heading2, copy2, impactGrid } = data.craft.entry;
  const resolvedData = {
    impact: {
      heading: heading2,
      copy: copy2,
      impact: impactGrid.map(card => {
        return {
          ...card,
          image: resolveImage(card.image),
          logo: resolveImage(card.logo),
        };
      }),
    },
  };
  let { heading, copy, impact } = resolvedData.impact;
  if (_impact && _impact.length > 0) {
    impact = _impact;
  }
  if (_heading && _heading.length > 0) {
    heading = _heading;
  }
  if (_copy && _copy.length > 0) {
    copy = _copy;
  }
  let color = 1;
  const colors = {
    lakota: [
      {
        heading: "text-brown",
        copy: "text-white",
        background: "bg-red",
      },
      {
        heading: "text-red",
        copy: "text-brown",
        background: "bg-white",
      },
      {
        heading: "text-red",
        copy: "text-white",
        background: "bg-brown",
      },
    ],
    lgca: [
      {
        heading: "text-blue",
        copy: "text-black",
        background: "bg-yellow",
      },
      {
        heading: "text-red",
        copy: "text-black",
        background: "bg-white",
      },
      {
        heading: "text-yellow",
        copy: "text-white",
        background: "bg-teal",
      },
      {
        heading: "text-yellow",
        copy: "text-white",
        background: "bg-red",
      },
      {
        heading: "text-orange",
        copy: "text-white",
        background: "bg-blue",
      },
    ],
  };
  const impactChunks = chunkify([...impact], 2);
  return (
    <div className="relative py-24">
      <div className="relative">
        <Container>
          <HeadingWIcon
            heading={heading}
            icon={icon}
            className="mb-4 max-w-2xl lg:mb-6"
            inverse={inverse}
            colors={
              lgca ? { heading: "text-orange", text: "text-black" } : null
            }
          />
          {copy && <Text variant="body--large">{copy}</Text>}
          <div className="-mx-2 flex flex-wrap">
            {impact &&
              impactChunks.map((a, i) => {
                if (
                  i === 1 &&
                  impactChunks[0].length === impactChunks[1].length
                ) {
                  color = 0;
                }
                return (
                  <div key={`${i}_`} className="w-full px-2 sm:w-1/2">
                    {a.map((card, ii) => {
                      const { typeHandle } = card;
                      if (typeHandle === "statCard" || lgca) {
                        color += 1;
                      }
                      if (typeHandle === "excerpt") {
                        return (
                          <div
                            key={`${i}_${ii}`}
                            className="relative mb-4 w-full lg:mb-6"
                          >
                            <CardExcerpt
                              {...card}
                              color={
                                (color + colors[brand.brand].length) %
                                colors[brand.brand].length
                              }
                              colors={colors}
                            />
                          </div>
                        );
                      }
                      if (typeHandle === "card") {
                        return (
                          <div
                            key={`${i}_${ii}`}
                            className="relative mb-4 w-full lg:mb-6"
                          >
                            <CardArticle
                              {...card}
                              color={
                                (color + colors[brand.brand].length) %
                                colors[brand.brand].length
                              }
                              colors={colors}
                            />
                          </div>
                        );
                      }
                      if (typeHandle === "statCard") {
                        return (
                          <div
                            key={`${i}_${ii}`}
                            className="relative mb-4 w-full lg:mb-6"
                          >
                            <CardStat
                              {...card}
                              color={
                                (color + colors[brand.brand].length) %
                                colors[brand.brand].length
                              }
                              colors={colors}
                            />
                          </div>
                        );
                      }
                      if (typeHandle === "quote") {
                        return (
                          <div
                            key={`${i}_${ii}`}
                            className="relative mb-4 w-full lg:mb-6"
                          >
                            <CardQuote
                              {...card}
                              color={
                                (color + colors[brand.brand].length) %
                                colors[brand.brand].length
                              }
                              colors={colors}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                );
              })}
          </div>
          {link && link.link && (
            <div className="mt-12 flex items-center justify-center">
              <Button
                size="sm"
                to={link.link}
                rounded={romero}
                color={romero ? "blue" : "red"}
              >
                {link.text}
              </Button>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

ImpactGrid.defaultProps = {
  inverse: false,
  icon: "sunrise",
};

export default ImpactGrid;
